import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UserService } from "../../services/user.service";
import { CategoryService } from "../../services/category.service";
import { PostService } from "../../services/post.service";
import { Post } from "../../models/post";
import { global } from "../../services/global";

@Component({
  selector: 'app-post-edit',
  templateUrl: '../post-new/post-new.component.html',
  providers: [UserService, CategoryService, PostService]
})
export class PostEditComponent implements OnInit {
  public page_title: string;
  public identity;
  public token;
  public post: Post;
  public categories;
  public user_id;
  public status;
  public is_edit: boolean;
  public url: string;

  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
  };

  public afuConfig = {
    multiple: false,
    formatsAllowed: ".jpg,.png,.gif,.jpeg",
    maxSize: "50",
    uploadAPI:  {
      url: global.url + 'post/upload',
      method:"POST",
      headers: {
        "Authorization": this._userService.getToken()
      },
      responseType: 'json',
    },
    theme: "attachPin",
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: false,
    fileNameIndex: true,
    replaceTexts: {
      selectFileBtn: 'Seleccionar archivo',
      resetBtn: 'Reset',
      uploadBtn: 'Subir',
      dragNDropBox: 'Arrastrar y soltar',
      attachPinBtn: 'Sube tu imagen para el post...',
      afterUploadMsg_success: '¡ Subido correctamente !',
      afterUploadMsg_error: '¡ Fallo en la subida !',
      sizeLimit: 'Tamaño máx.'
    }
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _categoryService: CategoryService,
    private _postService: PostService
  ) {
    this.page_title = 'Editar entrada';
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.is_edit = true;
    this.url = global.url;
  }

  ngOnInit(): void {
    this.getCategories();
    this.user_id = this.identity.id;
    this.post = new Post(1, this.user_id, 1, '', '', null, null);
    this.getPost();
  }

  getCategories(){
    this._categoryService.getCategories().subscribe(
      response =>{
        if (response.status == 'success') {
          this.categories = response.categories;
          console.log(this.categories);
        }
      },
      error =>{
        console.log(error);
      }
    );
  }

  getPost(){
    //Sacar el id del post de la url
      this._route.params.subscribe(params => {
        let id = params['id'];

        // Petición ajax para sacar los datos del post
        this._postService.getPost(id).subscribe(
          response => {
            if(response.status == 'success'){
              this.post = response.posts;
            }
          },
          error => {
            this._router.navigate(['inicio']);
          }
        );

      });

  }

  avatarUpload(datos){
    console.log(datos.body.image);
    let data_image = datos.body.image;
    this.post.image = data_image;
    this.identity.image = data_image;
  }
  onSubmit(form){
    this._postService.update(this.token, this.post, this.post.id).subscribe(
      response =>{
        if (response.status == 'success') {
          this.status = 'success';
          //this.status = response.post;
          // Redirigir a la página del post
          this._router.navigate(['/entrada', this.post.id]);
        }else{
          this.status == 'error';
        }
      },
      error =>{
        this.status == 'error';
      }
    );
  }


}

