<div class="col-md-12" *ngIf="!post">
    Cargando...
</div>

<div class="col-md-12" *ngIf="post">
    <h1>
        {{post.title}}
    </h1>
    <p>
        Categoria: <b>{{post['category'].name}}</b> - Autor:
        
        <a [routerLink]="['/perfil', post.user.id]"> 
            {{post['user'].name}} 
            {{post['user'].surname}} 
        </a>
    </p>
    <hr>
    <p [innerHTML]="post.content">
    </p>

</div>
<br>
