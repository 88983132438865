<div class="col-md-12 mt-3">
    <h2>
        {{page_title}}
    </h2>
    <p *ngIf="!is_edit">
        Añade un nuevo artículo al blog
    </p>
    <p *ngIf="is_edit && post">
        Modifica el post {{post.title}}
    </p>
    <hr>

    <!-- Mensajes de alerta -->
    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        Los datos se han guardado correctamente...!!
    </div>

    <div class="alert alert-danger col-md-5" *ngIf="status == 'error'">
        Los datos no se han guardado
    </div>

    <!-- Formulario de crear entrada-->
    <form class="col-md-5 ml-0 pl-0" #postNew="ngForm" (ngSubmit)="onSubmit(postNew)">

        <div class="form-group">
            <label for="title">Título o nombre completo</label>
            <input type="text" name="title" #title="ngModel" [(ngModel)]="post.title" class="form-control" required />
            <small *ngIf="!title.valid && title.touched" class="invalid_feedback d-block">
                El título no es válido
            </small>
        </div>

        <div class="form-group">
            <label for="content">Contenido o descripción</label>
            <textarea name="content" #content="ngModel" [(ngModel)]="post.content" class="form-control" required></textarea>
            <small *ngIf="!content.valid && content.touched" class="invalid_feedback d-block">
                El contenido no es válido
            </small>
        </div>

        <div class="form-group">
            <label for="category_id">¿Qué quieres crear?</label>
            <select name="category_id" #category="ngModel" [(ngModel)]="post.category_id" class="form-control">
                <option *ngFor="let category of categories" value="{{category.id}}">
                    {{category.name}}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label for="image">Imagen</label>

            <div *ngIf="is_edit && post.image">
                <img src="{{url + 'user/avatar/' + post.image}}" class="avatar_edit">
            </div>

            <angular-file-uploader
                [config]="afuConfig"
                [resetUpload]="true"
                (ApiResponse)="avatarUpload($event)">
            </angular-file-uploader>
        </div>

        <input type="submit" value="Guardar" class="btn btn-success" [disabled]="postNew.invalid">
    </form>

    <br>
</div>
